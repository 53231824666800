<template>
  <div class="user" :class="{ 'not-full': notFull }">
    <div class="user-inner">
      <div class="tabs">
        <div class="underline" :style="`left: ${activeTab.left}`"></div>
        <router-link
          v-for="item in tabs"
          class="tab-item"
          :key="item.route"
          :class="{ active: isActiveTab(item) }"
          :to="`/web/user/${item.route}`"
        >
          {{ item.label }}
        </router-link>
      </div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
const TABS = [
  {
    label: "个人中心",
    route: "center",
    left: "58px"
  },
  {
    label: "我的收益",
    route: "benefit",
    left: "158px"
  },
  {
    label: "我的团队成员",
    route: "teams",
    left: "272px"
  },
  {
    label: "我的谱书",
    route: "my-pushu",
    children: ["upload"],
    left: "388px"
  },
  {
    label: "已观看收费谱书",
    route: "watched",
    left: "510px"
  },
  {
    label: "我的发布",
    route: "my-events",
    left: "630px"
  }
];

export default {
  name: "User",
  data() {
    return {
      tabs: TABS
    };
  },
  computed: {
    activeTab() {
      return TABS.find(
        tab =>
          this.$route.path.includes(`/web/user/${tab.route}`) ||
          tab.children?.some(t => this.$route.path.includes(`/web/user/${t}`))
      );
    },

    notFull() {
      return this.$route.path.includes(`/web/user/my-pushu/`);
    }
  },
  created() {
    // this.fetchVipIndex();
  },
  methods: {
    // ...mapActions(["fetchVipIndex"]),
    isActiveTab(tab) {
      return (
        this.$route.path.includes(`/web/user/${tab.route}`) ||
        tab.children?.some(t => this.$route.path.includes(`/web/user/${t}`))
      );
    }
  }
};
</script>

<style lang="scss" scope>
.user {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  height: calc(100vh - 70px);

  &.not-full {
    min-height: calc(100vh - 70px);
    height: auto;
    overflow: scroll;
  }

  .tabs {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 0 20px;
    position: relative;

    .underline {
      width: 18px;
      height: 2px;
      background: #ca0a15;
      position: absolute;
      bottom: 1px;
      transition: left 0.3s ease;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 20px;
      content: "";
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      background-color: #f0f0f0;
    }

    .tab-item {
      padding: 0 20px;
      font-size: 14px;
      color: #666666;
      line-height: 50px;
      text-decoration: none;

      &.active {
        color: #ca0a15;
      }
    }
  }

  .user-inner {
    width: 1200px;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
